// Мобильное меню бургер
function burgerMenu() {
  let burger = document.querySelector(".burger");
  let menu = document.querySelector(".menu__list");
  burger.addEventListener("click", () => {
    if (!menu.classList.contains("active")) {
      menu.classList.add("active");
      burger.classList.add("active-burger");
    } else {
      menu.classList.remove("active");
      burger.classList.remove("active-burger");
    }
  });
  window.addEventListener("resize", () => {
    if (window.innerWidth > 885) {
      menu.classList.remove("active");
      burger.classList.remove("active-burger");
    }
  });
}
burgerMenu();
// Аккордеон
function accordion() {
  const items = document.querySelectorAll(".accordion__item-trigger");
  items.forEach((item) => {
    item.addEventListener("click", () => {
      const parent = item.parentNode;
      if (parent.classList.contains("accordion__item-active")) {
        parent.classList.remove("accordion__item-active");
      } else {
        document
          .querySelectorAll(".accordion__item")
          .forEach((child) => child.classList.remove("accordion__item-active"));
        parent.classList.add("accordion__item-active");
      }
    });
  });
}
accordion();
// Модальное окно (вызов, закрыть)

function openForm(nameId) {
  document.getElementById(nameId).style.display = "block";
}
function closeForm(nameId) {
  document.getElementById(nameId).style.display = "none";
}
//
// подмена лого
function replaceLogo() {
  const winWidth = window.innerWidth;
  const logo = document.querySelector("#logo > img");
  if (winWidth < 490) {
    const imgLogo = logo.getAttribute("src");
    if (!imgLogo.includes("logo.")) {
      const replaceLogo = /logo_text./g;
      return logo.setAttribute("src", imgLogo.replace(replaceLogo, "logo."));
    }
  }
}
window.addEventListener("resize", replaceLogo);
document.addEventListener("DOMContentLoaded", replaceLogo);
//// подсветка меню
function activMenu() {
  document.querySelectorAll(".menu__list-item a").forEach(function (el) {
    if (window.location == el.getAttribute("href")) {
      el.parentNode.classList.add("active");
    }
  });
}
activMenu();
// // отправка формы
// function submitHandler(e) {
//   e.preventDefault();

//   const request = new XMLHttpRequest();
//   request.onreadystatechange = function () {
//     console.log("readyState=", this.readyState, "statis=", this.status);
//     if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
//       console.log("good!");
//     }
//   };

//   request.open("POST", "mail.php", true);
//   request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

//   const data = new FormData(this);
//   for (var key of data.keys()) request.send(data);
// }

// document
//   .querySelectorAll("form")
//   .forEach((form) => form.addEventListener("submit", submitHandler));

//////////////

const sendMail = () => {
  let form = document.querySelectorAll("form");
  form.forEach((item) => {
    item.addEventListener("submit", function (evt) {
      evt.preventDefault();
      let formData;
      if (item.getAttribute("id") === "contac-form-page") {
        formData = {
          name: item.querySelector('input[name="name"]').value,
          phone: item.querySelector('input[name="phone"]').value,
          email: item.querySelector('input[email="email"]').value,
          massage: item.querySelector('textarea[message="message"]').value,
        };
      } else {
        formData = {
          name: item.querySelector('input[name="name"]').value,
          phone: item.querySelector('input[name="phone"]').value,
        };
      }
      let request = new XMLHttpRequest();
      request.addEventListener("load", function () {
        openForm("thanks-window");
        closeForm("myForm");
      });
      request.open("POST", "mail.php", true);
      request.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded; charset=UTF-8"
      );
      if (item.getAttribute("id") === "contac-form-page") {
        request.send(
          "name=" +
            encodeURIComponent(formData.name) +
            "&phone=" +
            encodeURIComponent(formData.name) +
            "&email=" +
            encodeURIComponent(formData.email) +
            "&massage=" +
            encodeURIComponent(formData.massage)
        );
      } else {
        request.send(
          "name=" +
            encodeURIComponent(formData.name) +
            "&phone=" +
            encodeURIComponent(formData.phone)
        );
      }
    });
  });
};
sendMail();

/////////////////////

var id;
var player;

function onYouTubeIframeAPIReady() {
  player = new YT.Player("player", {
    height: "315",
    width: "560",
    videoId: id,
    events: {
      onStateChange: onPlayerStateChange,
    },
  });
}

function onPlayerStateChange(event) {
  if (event.data == YT.PlayerState.PLAYING) {
    //player is playing
  } else {
    //player is paused
  }
}

function stopVideo() {
  player.stopVideo();
}

function playVideo() {
  player.playVideo();
}

function pauseVideo() {
  player.pauseVideo();
}

const openInModalYuotubeVideo = () => {
  if (
    document.querySelectorAll(".videos__item") &&
    document.querySelector("#videoModal")
  ) {
    const videos = document.querySelectorAll(".videos__item");
    const modal = document.querySelector("#videoModal");

    const openModal = () => {
      modal.classList.add("active");
      let onYouTubeAPIReady = false;
      if (document.querySelector("iframe")) {
        onYouTubeAPIReady = true;
        document.querySelector("iframe").remove();
        const div = document.createElement("div");
        div.setAttribute("id", "player");
        modal.append(div);
      }
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      if (onYouTubeAPIReady) {
        onYouTubeIframeAPIReady();
      }
    };

    const closeModal = () => {
      modal.classList.remove("active");
    };

    videos.forEach((item) => {
      let linck = item.querySelector("a");
      linck.addEventListener("click", (event) => {
        event.preventDefault();
        linck = item.querySelector("a");
        id = linck.getAttribute("href").split("/")[
          linck.getAttribute("href").split("/").length - 1
        ];
        openModal();
      });
    });
    modal
      .querySelector(".close-reveal-modal")
      .addEventListener("click", closeModal);
  }
};
openInModalYuotubeVideo();
